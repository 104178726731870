/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/custom.css"
import { Container, Row, Col, Form, Button } from "react-bootstrap";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <footer>
          <Container>
            <Row>
              <Col lg={5}>
                <h2>Remember…</h2>
                <p>Please consult your physician for personalized medical advice. Always seek the advice of a physician or other qualified healthcare provider with any questions regarding a medical condition. Never disregard or delay seeking professional medical advice or treatment because of something you have read or listened to on the Dr. Humankind website.</p>
              </Col>
              <Col lg={7}>
                <h2>Sign Up for Dr. Humankind's mailing list</h2>
                <script src="https://f.convertkit.com/ckjs/ck.5.js" />
      <Form action="https://app.convertkit.com/forms/1738841/subscriptions" class="seva-form formkit-form" method="post" data-sv-form="1738841" data-uid="ff0c9f9985" data-format="inline" data-version="5" data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;You're almost there! Check your e-mail for the confirmation link. Note, you might need to check your spam filter too.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">

        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Control
              required
              name="fields[first_name]"
              type="text"
              placeholder="First name"
            />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Control
              required
              name="email_address"
              type="email"
              placeholder="Email Address"
            />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Button variant="outline-light" type="submit" data-element="submit">Subscribe</Button>
          </Form.Group>
        </Form.Row>
        
        <div data-style="clean">
          
          <div data-element="fields" data-stacked="false" class="seva-fields formkit-fields">
            
      
      <div class="formkit-field">
        
        
        
        </div>
        <div class="formkit-spinner"><div></div><div></div><div></div></div><span class=""></span></div><div class="formkit-powered-by-convertkit-container"></div></div>
        </Form>
              </Col>
            </Row>
          </Container>
          <Container className="text-center mt-4">
            <p>© {new Date().getFullYear()}, Dr. Humankind</p>
            <p>Site designed by <a href="https://nautilusdesigns.com">Nautilus Designs</a></p>
          </Container>
          
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
