import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import dhkLogo from "../images/dhk-main-logo.svg"

import { Container, Navbar, Nav } from 'react-bootstrap';

const Header = () => (
  <header>
    <Container>
      
      <Navbar expand="md" collapseOnSelect={true}>
            <Link to="/">
            <img src={dhkLogo} alt="Dr. Humankind" className="maxLogoWidth" />
            </Link>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive" className="justify-content-center">
              <Nav>
              <ul className="navbar-nav">
            
                <li><Link to="/about/" className="nav-link" activeClassName="active">About</Link></li>
                <li><Link to="/podcast/" className="nav-link" activeClassName="active">Podcast</Link></li>
                <li><Link to="/contact/" className="nav-link" activeClassName="active">Contact</Link></li>
              </ul>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Container>
  </header>
)


export default Header
